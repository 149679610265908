import axios from 'axios';
import {userFio} from '../utils/userUtils';

const USERS = {
    LIST: 'USERS_LIST',
    LIST_PENDING: 'USERS_LIST_PENDING',
    LIST_ERROR: 'USERS_LIST_ERROR',
    USER: 'USER_LOAD',
    USER_PENDING: 'USER_PENDING',
    SAVED: 'USER_SAVED',
    CREATED: 'USER_CREATED',
    LOAD_USER_CAMERAS_LIST: 'USER_CAMERAS_LIST',
    LOAD_USER_NOTIFICATION_SETTINGS: 'USER_NOTIFICATION_SETTINGS',
    CLEAR: 'USERS_CLEAR',
    USER_CLEAR: 'USER_CLEAR',
}

const initialState = {};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case USERS.LIST:
            return {
                ...state,
                list: action.payload
                    // .filter(user => user.type === 'NativeUser')
                    .map(user => ({
                        ...user,
                        fio: userFio(user)
                    })),
                isListPending: false,
                isListError: false,
            };
        case USERS.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case USERS.LIST_ERROR:
            return {
                ...state,
                isListError: true,
            }
        case USERS.USER:
            return {
                ...state,
                current: action.payload,
                isUserPending: false,
            }
        case USERS.SAVED:
            return {
                ...state,
                // current: null,
                isUserPending: false,
            }
        case USERS.USER_PENDING:
            return {
                ...state,
                isUserPending: true,
            }
        case USERS.CLEAR:
            return {
                ...state,
                list: undefined
            };
        case USERS.USER_CLEAR:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};

export default usersReducer;

export const loadUsersList = (defaultAccountType) => dispatch => {
    dispatch({
        type: USERS.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/auth/users/')
        .then(res => {
            dispatch({
                type: USERS.LIST,
                payload: res.data,
            });
        })
        .catch(() => {
            dispatch({
                type: USERS.LIST_ERROR,
                payload: null,
            })
        })
};

export const loadUser = (id) => dispatch => {
    dispatch({
        type: USERS.USER_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-backend/users/${id}/permissions/`)
        .then(res1 => {
            axios.get(`/api/statistics/auth/users/${id}/`)
                .then(res2 => {
                    axios.get(`/api/statistics/kola-backend/users/${id}/accesses/`)
                        .then(res3 => {
                            dispatch({
                                type: USERS.USER,
                                payload: {
                                    'permissions': {...res1.data},
                                    'accessesList': {...res3.data},
                                    ...res2.data
                                }
                            })
                        })
                })
        })
        .catch(() => {
            axios.get(`/api/statistics/auth/users/${id}/`)
                .then(res2 => {
                    axios.get(`/api/statistics/kola-backend/users/${id}/accesses/`)
                        .then(res3 => {
                            dispatch({
                                type: USERS.USER,
                                payload: {
                                    'accessesList': {...res3.data},
                                    ...res2.data
                                }
                            })
                    })
                })
        })
}

export const loadUserAccesses = (id) => dispatch => {
    dispatch({
        type: USERS.USER_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-backend/users/${id}/accesses/`)
        .then(res3 => {
            dispatch({
                type: USERS.USER,
                payload: {
                    'accessesList': {...res3.data},
                }
            })
        })
}

export const saveUser = (id, data) => dispatch => {
    dispatch({
        type: USERS.USER_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/auth/users/${id}/`, data)
        .then(res => {
            dispatch({
                type: USERS.SAVED,
                payload: data,
            });
            dispatch(clearUsersState());
            dispatch(loadUsersList());
        });
};

export const updateUser = (id, info, accecess, permissions, onClose) => dispatch => {
    dispatch({
        type: USERS.USER_PENDING,
        payload: null,
    });

   return axios.put(`/api/statistics/auth/users/${id}/`, info).then(() => {
        return axios.put(`/api/statistics/kola-backend/users/${id}/accesses/`, accecess)
    }).then(() => {
        return axios.put(`/api/statistics/kola-backend/users/${id}/permissions/`, permissions)
    }).then(() => {
        dispatch(clearUsersState());
        dispatch(loadUsersList());
        onClose()
    }).finally(() => {
        dispatch({
            type: USERS.SAVED,
            payload: null,
        });
    })
};

export const saveUserPermissions = (id, data) => dispatch => {
    dispatch({
        type: USERS.USER_PENDING,
        payload: null,
    });
    axios.put(`/api/statistics/kola-backend/users/${id}/permissions/`, data)
        .then(res => {
            dispatch({
                type: USERS.SAVED,
                payload: data,
            });
            dispatch(clearUsersState());
            dispatch(loadUsersList());
        });
};

export const saveUserAccesses = (id, data) => dispatch => {
    dispatch({
        type: USERS.USER_PENDING,
        payload: null,
    });

    axios.put(`/api/statistics/kola-backend/users/${id}/accesses/`, data)
        .then(res => {
            dispatch({
                type: USERS.SAVED,
                payload: data,
            });
            dispatch(clearUsersState());
            dispatch(loadUsersList());
        });
};

export const createUser = (data) => dispatch => {
    axios.post('/api/statistics/auth/users/', data)
        .then(res => {
            dispatch(loadUsersList());
        });
};

export const deleteUser = (id) => dispatch => {
    dispatch({
        type: USERS.LIST_PENDING,
        payload: null,
    });
    axios.delete(`/api/statistics/auth/users/${id}/`)
        .then(res => {
            dispatch(clearUsersState());
            dispatch(loadUsersList());
        });
};

export const clearUsersState = () => dispatch => {
    dispatch({
        type: USERS.CLEAR,
        payload: null,
    });
};

export const clearUserState = () => dispatch => {
    dispatch({
        type: USERS.USER_CLEAR,
        payload: null
    })
}
