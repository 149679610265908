import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {loadObjectsObservationList} from '../store/ObjectObservation';
import {clearEventReport, loadEventReport} from '../store/Events';
import {omitUndefinedAndNullFields} from '../utils/objects';
import Modal from '../components/Modal';
import Event from '../components/Event';
import {DEFAULT_INTERVAL_HOURS, PAGE_SIZE, START_PAGE} from '../utils/constants';
import withDataErrorIndication from '../components/withDataErrorIndication';
import Spinner from "../components/Spinner";
import {cameraFlatDetailedList} from "../store/Camera";
import RemotePagination from "./RemotePagination";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {customFilter, FILTER_TYPES, selectFilter, textFilter} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {apiDataToEventObj, cameraInObject, omitRooms, onlyRooms, roomsInObject} from "../utils/utils";
import {objectsListToOptions} from "../utils/inputs";
import RangeFilter from "../components/RangeFilter";
import CustomSelect from '../components/CustomSelect'
import {getDateNow} from "../utils/time";
import {clearEmploeesState, loadEmploeesList} from "../store/Employees";
import {userFio} from "../utils/userUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt, faArrowUp, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {thermalCameraList} from "../store/ThermalCamera";
import { useRouter, useRoute } from 'react-router5';
import {
    videoRecorderClearState,
    videoRecorderList,
} from '../store/VideoRecorder';
import { isEqual } from 'lodash';
import { get_current_event } from '../store/Event';
import axios from 'axios';

const filterStatus = {
    CONFIRMED: 'Нарушение',
    DENIED: 'Не нарушение',
    NO_STATUS: 'Не проверено',
};

const eventSortEnum = {
    unspecifed: 0,
    eventIdAsc: 1,
    eventIdDesc: 2,
    timestampAsc: 3,
    timestampDesc: 4,
    scoreAsc: 5,
    scoreDesc: 6,
}

const EventsPage = ({        defaultQuery, loadEvents, clearEvents, loadObjects, employees, loadEmployeesList, clearEvent,
    events, objects, isViolationsOnly, isPendingEvents, loadAllCameras, cameras = [], eventsTotal, isPendingObjects,
    thermalCameras = [], loadThermalCamerasData, videoRecorderList = [], loadAllVideoRecorder, eventId, get_current_event, current_event, current_event_isPending, isCurrentEventError}) => {
    const router = useRouter()
    const route = useRoute()
    const eventInputName = `ID ${window.location.pathname.includes('violations') ? 'нарушения' : 'события'}`
    const currentEventError = `${window.location.pathname.includes('violations') ? 'Нарушение' : 'Событие'} не найдено`

    const [page, setPage] = useState(START_PAGE);
    const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
    const [filtersList, setfiltersList] = useState();
    const [event, setEvent] = useState(null);
    const [apiEvents, setApiEvents] = useState(null)
    const [unitId, setUnitId] = useState(null);
    const [objectId, setObjectId] = useState(null);
    const [secondaryCategoriesOptions, setSecondaryCategoriesOptions] = useState([])
    const [employessOptions, setEmployessOptions] = useState([])
    const [queryParamsObj, setQueryParamsObj] = useState(null)
    const [isFirstRenderReady, setFirstRenderReady] = useState(false)
    const [currentEventId, setCurrentEventId] = useState('')

    const convertFilters = useCallback((isQueryString, filtersList, sortField, sortOrder) => {
        const obj = {}

        if (!filtersList) return null

        const secondary_categories_map = filtersList?.type?.filterVal?.map((item) => item?.value)
        const person_ids_map = filtersList?.person?.filterVal?.map((item) => item?.value)
        const power_unit = filtersList?.power_unit?.filterVal
        const object_id = filtersList?.room?.filterVal
        const cameraId = filtersList?.camera_name?.filterVal
        const dateBegin = filtersList?.date?.filterVal.from
        const dateEnd = filtersList?.date?.filterVal.to
        const hasViolation = filtersList?.confirmation_status?.filterVal
        const score_gte = filtersList?.score?.filterVal
        const secondary_categories = filtersList?.type?.filterVal
        const person_ids = filtersList?.person?.filterVal
       
        if (sortField && sortOrder) {
            let paramVal

            if (sortField === 'date') {
                paramVal = sortOrder === 'asc' ? eventSortEnum.timestampAsc : eventSortEnum.timestampDesc
            }

            if (sortField === 'score') {
                paramVal = sortOrder === 'asc' ? eventSortEnum.scoreAsc : eventSortEnum.scoreDesc
            }

            obj.sorting = paramVal
        }

        if (power_unit) {
            obj.power_unit = power_unit
        }

        if (object_id) {
            obj.object_id = object_id
        }

        if (cameraId) {
            obj.cameraId = cameraId
        }

        if (dateBegin) {
            obj.dateBegin = dateBegin
        }

        if (dateEnd) {
            obj.dateEnd = dateEnd
        }

        if (hasViolation) {
            obj.hasViolation = hasViolation
        }

        if (score_gte) {
            obj.score_gte = score_gte
        }

        if (secondary_categories) {
            const arr = isQueryString ? secondary_categories_map.join('&') : secondary_categories_map

            obj.secondary_categories = arr
        }

        if (person_ids) {
            const arr = isQueryString ? person_ids_map.join('&') : person_ids_map

            obj.person_ids = arr
        }

        return Object.keys(obj)?.length === 0 ? null : obj
    }, []) 


    useEffect(() => {
        loadObjects();
        loadAllCameras();
        loadThermalCamerasData();
        loadEmployeesList();
        loadAllVideoRecorder();

        axios.get('/api/inference/models-storage/')
        .then(res => {
            const data = res?.data
            const options = data?.secondary_categories?.map((item) => ({value: item.name , label: item.ru_name}))

            setSecondaryCategoriesOptions(options)
        },
        )
    }, []);

    useEffect(() => {
        if (employees) {
            const options = employees?.map((item) => ({value: item.id , label: `${item.surname || ''} ${item.firstname ? `${item.firstname[0]}.` : ''} ${item.patronymic ? `${item.patronymic[0]}.` : ''}`}))

            setEmployessOptions(options)
        }
    }, [employees])

    useEffect(() => {
        const params = route.route.params
        const filters = {}

        if (employessOptions.length && secondaryCategoriesOptions.length) {
            if (Object.keys(params)?.length) {
                if (params?.power_unit) {
                    filters.power_unit = {
                        filterVal: params.power_unit,
                        filterType: "SELECT",
                        comparator: "=",
                        caseSensitive: false
                    }
                }
        
                if (params?.object_id) {
                    filters.room = {
                        filterVal: params.object_id,
                        filterType: "SELECT",
                        comparator: "=",
                        caseSensitive: false
                    }
                }
        
                if (params?.cameraId) {
                    filters.camera_name = {
                        filterVal: params.cameraId,
                        filterType: "SELECT",
                        comparator: "=",
                        caseSensitive: false
                    }
                }
        
                if (params?.dateBegin && params?.dateEnd) {
                    filters.date = {
                        filterVal: {
                            from: new Date(params?.dateBegin),
                            to: new Date(params?.dateEnd)
                        },
                        filterType: "DATE",
                        comparator: "LIKE",
                        caseSensitive: false
                    }
                }
        
                if (params?.score_gte) {
                    filters.score = {
                        filterVal: params?.score_gte,
                        filterType: "TEXT",
                        comparator: "LIKE",
                        caseSensitive: false
                    }
                }
        
                if (params?.person_ids) {
                    const arr = params?.person_ids?.includes('&') ? params?.person_ids?.split('&') : [params?.person_ids]
                    const options = arr?.map((item) => {
                        const employe = employessOptions.find((employe) => employe.value === Number(item))
    
                        return {value: item, label: employe?.label}
                    })
        
                    filters.person = {
                        filterVal: options,
                        filterType: "MULTISELECT",
                        comparator: "LIKE",
                        caseSensitive: false
                    }
                }
        
                if (params?.secondary_categories) {
                    const arr = params?.secondary_categories?.includes('&') ? params?.secondary_categories?.split('&') : [params?.secondary_categories]
                    const options = arr?.map((item) => {
                        const employe = secondaryCategoriesOptions.find((employe) => employe.value === item)
    
                        return {value: item, label: employe?.label}
                    })
        
                    filters.type = {
                        filterVal: options,
                        filterType: "MULTISELECT",
                        comparator: "LIKE",
                        caseSensitive: false
                    }
                }
        
        
                if (params?.hasViolation) {
                    filters.confirmation_status = {
                        filterVal: params?.hasViolation,
                        filterType: "SELECT",
                        comparator: "=",
                        caseSensitive: false
                    }
                }
            }
            setfiltersList(filters)
        }
    }, [employessOptions, secondaryCategoriesOptions])

    const getSortData = () => {
        const params = route.route.params
        const defaultData = {sortField: undefined, sortOrder: undefined}
        const sortingNumber = Number(params?.sorting)

        if (!sortingNumber) return defaultData

        switch (sortingNumber) {
            case eventSortEnum.scoreAsc:
                return {sortField: 'score', sortOrder: 'asc'}
            case eventSortEnum.scoreDesc:
                return {sortField: 'score', sortOrder: 'desc'}
            case eventSortEnum.timestampAsc:
                return {sortField: 'date', sortOrder: 'asc'}
            case eventSortEnum.timestampDesc:
                return {sortField: 'date', sortOrder: 'desc'}
            default:
                return defaultData
        }

    }

    useEffect(() => {
        const params = route.route.params

        if (Object.keys(params).length && convertFilters(true, filtersList, getSortData()?.sortField, getSortData()?.sortOrder) && !isFirstRenderReady) {
            setFirstRenderReady(true)
            router.navigate(window.location.pathname.includes('violations') ?  'VIOLATIONS' : 'EVENTS', convertFilters(true, filtersList, getSortData()?.sortField, getSortData()?.sortOrder));
            setQueryParamsObj(convertFilters(false, filtersList, getSortData()?.sortField, getSortData()?.sortOrder))
        }
     }, [convertFilters, filtersList, isFirstRenderReady])

     
    useEffect(() => {
        const FilteredData = Object.entries(events || []).filter(item => item[1].primary_objects.length !== 0).filter(item => item[1].primary_objects[0].secondary_objects.length !== 0).filter(item => item[1].object_observation !== null);
        const data = FilteredData.map((event) => apiDataToEventObj(event[1], objects.list));
        setApiEvents(data)
  
    }, [events])

    useEffect(() => {
        if (eventId) {
            get_current_event(eventId)
        }
    },[eventId])

    useEffect(() => {
        if (current_event?.id) {
            setEvent(current_event)
        }
    },[current_event])

    useEffect(() => {
        if (isCurrentEventError) {
            if (window.location.pathname.includes('violations')) {
                router.navigate('VIOLATIONS')
            } else {
                router.navigate('EVENTS')
            }
        }
    }, [isCurrentEventError])

    const reloadQuery = useCallback(() => {
        const params = route.route.params
        const paramsArr = Object.keys(params)

        if (paramsArr.length > 0) {
            if (queryParamsObj) { 
                loadEvents(page, sizePerPage, {
                    ...defaultQuery,
                    ...omitUndefinedAndNullFields(queryParamsObj) 
                })
            } 
        } else {
            const previousRoute = route?.previousRoute
            const isPreviousEvent = previousRoute ? Object.keys(previousRoute?.params).includes('eventId') : false
    
            if (!isPreviousEvent) {
                loadEvents(page, sizePerPage, defaultQuery)
            }
        }
    }, [queryParamsObj, page, sizePerPage]) 

    // useEffect(() => {
    //     const params = route.route.params
    //     const paramsArr = Object.keys(params)
    //     const previousRoute = route?.previousRoute
    //     const isPreviousEvent = previousRoute ? Object.keys(previousRoute?.params).includes('eventId') : false

    //     if (!paramsArr.length && !isPreviousEvent) {
    //         loadEvents(page, sizePerPage, defaultQuery)
    //     }
    // }, [route.route.params])

    useEffect(() => {
        reloadQuery();
    }, [reloadQuery]);
    
    if (isPendingObjects ) {
        return <Spinner/>;
    }

    const reload = () => {
        const queryParams = queryParamsObj ? omitUndefinedAndNullFields(queryParamsObj) : {}

        loadEvents(page, sizePerPage, {...queryParams, ...defaultQuery})
    };


    const onTableChange = (type, {sizePerPage, page, filters, sortField, sortOrder}) => {
        if (type === 'filter') {
            if (!isEqual(filters,filtersList)) {
                setPage(1)
            }
        } else {
            setPage(page);
            setSizePerPage(sizePerPage);
        }

        setfiltersList(filters);
        router.navigate(window.location.pathname.includes('violations') ?  'VIOLATIONS' : 'EVENTS', convertFilters(true, filters, sortField, sortOrder));
        setQueryParamsObj(convertFilters(false, filters, sortField, sortOrder))
    };

    const onCloseModal = () => {
        if (window.location.pathname.includes('violations')) {
            router.navigate('VIOLATIONS')
        } else {
            router.navigate('EVENTS')
        }
        clearEvent()
        setEvent(null);
    };

    const getPersonFIO = (person_id, persons) => {
        for (const p of persons) {
            if (person_id === p.id) {
                return userFio(p);
            }
        }
    };

    const columns = [{
        text: '№',
        dataField: 'id',
        headerStyle: () => ({width: '59px'})
    }, {
        text: 'Блок',
        dataField: 'power_unit',
        headerClasses: 'table-block',
        filter: selectFilter({
            options: objectsListToOptions(omitRooms(objects.list)),
            placeholder: 'Все энергоблоки',
            defaultValue: filtersList?.power_unit?.filterVal
        }),
        headerStyle: () => ({width: '159px'}),
        // formatter: cell => objectsListToOptions[cell],
    }, {
        text: 'Помещение',
        dataField: 'room',
        filter: selectFilter({
            options: objectsListToOptions(unitId ?
                roomsInObject(unitId, onlyRooms(objects.list)) : onlyRooms(objects.list)
            ),
            placeholder: 'Все помещения',
            defaultValue: filtersList?.room?.filterVal
        }),
        headerStyle: () => ({width: '159px'})
    }, {
        text: 'Камера',
        dataField: 'camera_name',
        filter: selectFilter({
            options: objectsListToOptions(objectId ?
                // cameraInObject(objectId, cameras) : cameras
                cameraInObject(objectId, [...cameras, ...thermalCameras, ...videoRecorderList]) : [...cameras, ...thermalCameras, ...videoRecorderList]
            ),
            placeholder: 'Все камеры',
            defaultValue: filtersList?.camera_name?.filterVal
        }),
        headerStyle: () => ({width: '159px'}),
    }, {
        text: '',
        dataField: 'date',
        sort: true,
        headerStyle: () => ({width: '220px'}),
        headerClasses: 'table-date',
        filter: customFilter({
            type: FILTER_TYPES.DATE,
        }),
        sortCaret: (sortVal) => getSortCaret(sortVal, 'Дата и время'),
        filterRenderer: (onFilter, column) => {
            return <RangeFilter
                onFilter={onFilter}
                valueFrom={filtersList?.date?.filterVal?.from || getDateNow(-DEFAULT_INTERVAL_HOURS)}
                valueTo={filtersList?.date?.filterVal?.to || getDateNow()}
                defaultValue={filtersList?.date?.filterVal}
                onClick={(ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                }}
            />
        }
          
    }, {
        text: '',
        dataField: 'score',
        sort: true,
        filter: textFilter({
            placeholder: 'Больше %',
            defaultValue: filtersList?.score?.filterVal
        }),
        headerStyle: () => ({width: '82px'}),
        sortCaret: (sortVal) => getSortCaret(sortVal, 'Индекс'),
    }, {
        text: 'Работник',
        dataField: 'person',
        filter: customFilter({
            type: FILTER_TYPES.MULTISELECT,
        }),
        filterRenderer: (onFilter) => {
            const defaultOptions = filtersList?.person?.filterVal

            return <CustomSelect 
                        isMulti
                        options={employessOptions}
                        placeholder=''
                        className="violation-multi-select"
                        classNamePrefix="violation-multi-select"
                        onFilter={onFilter}
                        defaultValue={defaultOptions}
                    />
        },
        formatter: cell => getPersonFIO(cell, employees),
    }, {
        text: 'Найденное нарушение',
        dataField: 'type',
        filter: customFilter({
            type: FILTER_TYPES.MULTISELECT,
        }),
        filterRenderer: (onFilter) => {
            const defaultOptions = filtersList?.type?.filterVal

            return <CustomSelect 
                isMulti
                options={secondaryCategoriesOptions}
                placeholder=''
                className="violation-multi-select"
                classNamePrefix="violation-multi-select"
                onFilter={onFilter}
                defaultValue={defaultOptions}
            />
        }, 
    }, {
        text: 'Статус',
        dataField: 'confirmation_status',
        headerStyle: () => ({width: '150px'}),
        formatter: cell => filterStatus[cell],
        ...(!isViolationsOnly && {
            filter: selectFilter({
                options: filterStatus,
                placeholder: 'Все',
                defaultValue: filtersList?.confirmation_status?.filterVal
            })
        })
    }]
        .map(col => ({
            ...col,
            editable: false,
            classes: 'px-0 py-0 table-cell',
        }));

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            // setEvent(row);
            if (window.location.pathname.includes('violations')) {
                router.navigate('VIOLATION', { eventId: row.id })
            } else {
                router.navigate('EVENT', { eventId: row.id })
            }
            
            // window.history.pushState(null, null, `/events/${row.id}`)
        }
    };

    const rowClass = (row, rowIndex) => {
        if (row.confirmation_status === "NO_STATUS") {
            return 'eventRow notChecked'
        }
        if (row.confirmation_status === "DENIED") {
            return 'eventRow noViolation'
        }
        if (row.confirmation_status === "CONFIRMED") {
            return 'eventRow violation'
        }
    };


    let tableRef;

    const handleChangeCurrentEventId = (ev) => {
        setCurrentEventId(ev.target.value)
    }

    const handleSearchEvent = () => {
       if (currentEventId) {
        setCurrentEventId('')

        if (window.location.pathname.includes('violations')) {
            router.navigate('VIOLATION', { eventId: Number(currentEventId) })
        } else {
            router.navigate('EVENT', { eventId: Number(currentEventId) })
        }
       }
    }

    const getSortCaret = (sortVal, name) => {
        return <div style={{display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '-2px', zIndex: 2}}>
          <p style={{fontSize: '13px'}}>{name}</p>
            <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
                <FontAwesomeIcon icon={faArrowUp} size="sm" color={sortVal === 'asc' ? "#1779ba" : "#919191"}/>

                <FontAwesomeIcon icon={faArrowDown} size="sm" color={sortVal === 'desc' ? "#1779ba" : "#919191"}/>
            </div>
        </div>
    }

    return (
        <>
            <div className="btn-wrapper with-other-btns" >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <button className="table-control-btn btn-reload" onClick={reload}>
                        <FontAwesomeIcon icon={faSyncAlt} size="lg" color="#919191"/>
                        Обновить
                    </button>

                    <input placeholder={eventInputName} value={currentEventId} onChange={handleChangeCurrentEventId} type='number' style={{border: '1px solid hsl(0,0%,80%)', height: '32px', width: '150px', margin: '0 17px 0 0', fontSize: '13px', outline: 'unset'}}/>

                    <button className="table-control-btn btn-reload" onClick={handleSearchEvent} style={{height: '32px'}}>
                        Поиск
                    </button>

                    {isCurrentEventError && <p style={{color: 'red', fontSize: '13px'}}>{currentEventError}</p>}
                </div>
            </div>
            <RemotePagination
                data={apiEvents ? apiEvents : []}
                totalSize={eventsTotal}
                sizePerPage={sizePerPage}
                page={page}
                onSizePerPageChange={(...params) => {
                    const filters = tableRef.filterContext.currFilters;
                    onTableChange(null, {sizePerPage: params[0], page: params[1], filters});
                }}
                onTableChange={(type, options) => {
                    if (type === 'filter') {
                        const object = options.filters.room;
                        setObjectId(object ? parseInt(object.filterVal) : null);
                        const unit = options.filters.power_unit;
                        setUnitId(unit ? parseInt(unit.filterVal) : null);
                    }

                    if (apiEvents) onTableChange(type, {sizePerPage: options.sizePerPage || sizePerPage, page: options.page, filters: options.filters, sortField : options?.sortField, sortOrder: options?.sortOrder});
                }}
            >
                <BootstrapTable
                    rowEvents={rowEvents}
                    remote={{filter: true, sort: true}}
                    ref={el => tableRef = el}
                    keyField="uniqKey"
                    data={apiEvents ? apiEvents : []}
                    columns={columns}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                    // onTableChange={onTableChange}
                    onTableChange={() => {}}
                    rowClasses={rowClass}
                    noDataIndication={() => isPendingEvents ? <Spinner/> : 'Данные отсутствуют'}
                    defaultSorted={getSortData()?.sortField ? [{dataField: getSortData().sortField, order: getSortData().sortOrder}] : undefined}
                />
            </RemotePagination>

            {event &&
            <Modal
                title={`Нарушение № ${event.id} (Камера - ${event.camera_name})`}
                isOpen
                className="big_modal"
                showPrintButton={true}
                onClose={onCloseModal}>
                <Event 
                       reload={() => reload()}
                       employees={employees}
                      
                />
            </Modal>}
        </>
    );
};

export default connect(
    state => ({
        user: state.user,
        videoRecorderList: state.videoRecorder.list || [],
        event: state.event.event,
        current_event: state.event?.current_event || {},
        current_event_isPending: state.event?.current_event_isPending || false,
        events: state?.events?.isPending ? [] : state.events.list?.events,
        eventsTotal: state.events.list?.total_events,
        cameras: state.camera.flatList || [],
        thermalCameras: state.thermalCamera.list || [],
        objects: state.object,
        employees: state.employees.list,
        isPendingEvents: state.events.isPending,
        isPendingObjects: state.videoRecorder.isListPending || state.object.isListPending || state.camera.isListPending || state.thermalCamera.isListPending || state.employees.isListPending,
        isError: state.events.isError,
        isCurrentEventError: state.event.isCurrentEventError,
    }),
    dispatch => ({
        loadEvents: (pageNumber, pageSize, query) => {
            // dispatch(clearEventReport());
            dispatch(loadEventReport(query, pageNumber, pageSize));
        },
        clearEvent: () => {
            // dispatch(clearEventReport());
            dispatch({type: 'CLEAR_EVENT'});
        },
        get_current_event: (eventId) => {
            dispatch(get_current_event(eventId));
        },
        clearEvents: () => {
            dispatch(clearEventReport());
        },
        loadAllVideoRecorder: () => {
            dispatch(videoRecorderClearState());
            dispatch(videoRecorderList());
        },
        loadObjects: () => {
            dispatch(loadObjectsObservationList());
        },
        loadAllCameras: () => {
            // dispatch(cameraClearState());
            dispatch(cameraFlatDetailedList());
        },
        loadThermalCamerasData: () => {
            dispatch(thermalCameraList());
        },
        loadEmployeesList: () => {
            dispatch(clearEmploeesState());
            dispatch(loadEmploeesList());
        },
    }),
)(withDataErrorIndication(EventsPage));
