import React from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {isAuthUser} from './utils/utils';
import websockets from './utils/websockets';

export default function withAuthHeaders(WrappedComponent) {
    return class extends React.Component {

        constructor(props) {
            super(props);
            this.REFRESH_WHEN_BEFORE_EXPIRATION_MS = -30 * 1000;
            this.axiosInterceptor = null;
            this.tokenRefreshTimer = null;
            this.closeWsConnection = null;
            // Был залогинен и перезагрузил страницу
            if (isAuthUser(this.props.user)) {
                this.setInterceptor(this.props.user);
                this.scheduleNextAccessTokenRefresh();
                // this.closeWsConnection = websockets.setupNotifications(this.props.user.userId, this.props.onMessageReceived);
            }
        }
        
        setInterceptor = (user) => {
            const context = this

            if (this.axiosInterceptor !== null) {
                axios.interceptors.request.eject(this.axiosInterceptor);
            }
            this.axiosInterceptor = axios.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${user.accessToken}`;
                return config;
            });

              
              axios.interceptors.response.use((response) => {
                return response
              }, async function (error) {
                  const originalRequest = error.config;

                if (error.response.status === 401 && error.response?.config?.url === '/api/statistics/auth/refresh/') {
                    context.props.onLogout()

                    return
                }

                if (error.response.status === 401 && !originalRequest._retry) {
                  originalRequest._retry = true;
                  const axiosApiInstance = axios.create();
                  const meta = jwtDecode(context.props.user.accessToken);

                  const access_token = await context.props.refreshAccessToken(meta.refresh_token);     
 
                  originalRequest.headers.Authorization = 'Bearer ' + access_token

                  return axiosApiInstance(originalRequest);
                }
                return Promise.reject(error);
              });
        };

        scheduleNextAccessTokenRefresh = () => {
            try {
                const meta = jwtDecode(this.props.user.accessToken);
                const interval = 240000 // 4 min

                this.tokenRefreshTimer = setTimeout(
                    () => {
                        this.props.refreshAccessToken(meta.refresh_token)
                    },
                    interval);
            } catch (ignored) {
            }
        };

        handleAuthEvents = (prevProps) => {
            // Залогинился заново или обновился токен
            if (isAuthUser(this.props.user) &&
                (!isAuthUser(prevProps.user)
                    || this.props.user.accessToken !== prevProps.user.accessToken)) {
                    this.setInterceptor(this.props.user);
                    this.scheduleNextAccessTokenRefresh();
                    // this.closeWsConnection = websockets.setupNotifications(this.props.user.userId, this.props.onMessageReceived);
            }
            // Разлогинился
            if (!isAuthUser(this.props.user) && isAuthUser(prevProps.user)) {
                clearTimeout(this.tokenRefreshTimer);
                axios.interceptors.request.eject(this.axiosInterceptor);
                if (this.closeWsConnection) {
                    this.closeWsConnection();
                }
            }
        };

        componentDidUpdate(prevProps, prevState, snapshot) {
            this.handleAuthEvents(prevProps);
        }

        render() {
            return (
                <WrappedComponent {...this.props} />
            )
        }
    };
}
